// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #356FD0;
  --ion-color-primary-rgb: 53, 111, 208;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #2f62b7;
  --ion-color-primary-tint: #497dd5;

  /** secondary **/
  --ion-color-secondary: #FF9D66;
  --ion-color-secondary-rgb: 255, 157, 102;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e08a5a;
  --ion-color-secondary-tint: #ffa775;

/** tertiary **/
--ion-color-tertiary: #f4a942;
--ion-color-tertiary-rgb: 244,169,66;
--ion-color-tertiary-contrast: #fff;
--ion-color-tertiary-contrast-rgb: 255,255,255;
--ion-color-tertiary-shade: #d7953a;
--ion-color-tertiary-tint: #f5b255;

/** success **/
--ion-color-success: #00C163;
--ion-color-success-rgb: 0, 193, 99;
--ion-color-success-contrast: #ffffff;
--ion-color-success-contrast-rgb: 255, 255, 255;
--ion-color-success-shade: #00aa57;
--ion-color-success-tint: #1ac773;

/** warning **/
--ion-color-warning: #ffce00;
--ion-color-warning-rgb: 255,206,0;
--ion-color-warning-contrast: #000;
--ion-color-warning-contrast-rgb: 0,0,0;
--ion-color-warning-shade: #e0b500;
--ion-color-warning-tint: #ffd31a;

/** danger **/
--ion-color-danger: #f53d3d;
--ion-color-danger-rgb: 245,61,61;
--ion-color-danger-contrast: #fff;
--ion-color-danger-contrast-rgb: 255,255,255;
--ion-color-danger-shade: #d83636;
--ion-color-danger-tint: #f65050;

/** light **/
// --ion-color-light: #f4f4f4;
// --ion-color-light-rgb: 244,244,244;
// --ion-color-light-contrast: #000;
// --ion-color-light-contrast-rgb: 0,0,0;
// --ion-color-light-shade: #8290e0;
// --ion-color-light-tint: #f5f5f5;
--ion-color-light: #EDEDED;
--ion-color-light-rgb: 237,237,237;
--ion-color-light-contrast: #000;
--ion-color-light-contrast-rgb: 0,0,0;
--ion-color-light-shade: #d1d1d1;
--ion-color-light-tint: #efefef;

/** medium **/
--ion-color-medium: #ccd1e1;
--ion-color-medium-rgb: 152,154,162;
--ion-color-medium-contrast: #000;
--ion-color-medium-contrast-rgb: 0,0,0;
--ion-color-medium-shade: #86888f;
--ion-color-medium-tint: #a2a4ab;

/** dark **/
--ion-color-dark: #110A3B;
--ion-color-dark-rgb: 34,34,34;
--ion-color-dark-contrast: #fff;
--ion-color-dark-contrast-rgb: 255,255,255;
--ion-color-dark-shade: #190e60;
--ion-color-dark-tint: #2e256d;
}

$enable-responsive-font-sizes: true;